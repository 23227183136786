import { Head } from 'next/head'
import Footer from '@/components/Footer'
import styles from '@/styles/main.css'
import error from '@/styles/error.css'
const metadata = {
	title: "404",
	description: "Page not found",
	name: 'Tyler Heshmati',
	description: 'Tyler Heshmati is a self taught software engineer based in the San Francisco Bay Area, CA.',
	link: 'https://www.linkedin.com/in/tylerheshmati/',
}

export default function Custom404() {
	return (<>
		<div className='next-error-Div1'>
			<div className='next-error-Div2'>
				<h1 className="next-error-h1">
					404
				</h1>
				<div className='next-error-Div3'>
					<h2 className="next-error-h2">Page Not Found.</h2>
				</div>
			</div>
		</div>
	</>)
  }
//12/22/2002 2:22pm